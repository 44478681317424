/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.line-error {
  font-size: 28px;
}
.line-box {
  margin: 0 auto;
  max-width: 650px;
  min-height: 500px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.line-box .content {
  padding: 32px 32px 24px;
  text-align: left;
}
.mobile .line-box .content {
  padding: 24px 16px 16px;
}
.line-box .loading-description {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #14837B;
  padding-top: 64px;
  text-align: center;
}
.line-title {
  text-align: center;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  color: #14837B;
}
.line-suggestion {
  text-align: center;
  color: #8c8c8c;
}
.line-info {
  margin: 16px auto;
}
.line-login-form {
  margin: 32px auto 24px;
  max-width: 400px;
}
.form-submit-row {
  display: flex;
}
.form-submit-row .submit-btn {
  margin-left: 8px;
  flex-grow: 1;
}
